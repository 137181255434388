import style from './Footer.module.scss';
import logo from './assets/img/logo.svg';
import Title from './../Title';
import Text from './../Text';
import whitepaperIcon from './assets/icons/whitepaperIcon';
import { siteContent } from './../../siteContent';
import './svgColor.scss';
import bg from './assets/img/bg.png';
import { useMediaQuery } from 'usehooks-ts';
import whBg from './assets/img/wh-bg.png';

const Footer = () => {
  const isMobile = useMediaQuery('(max-width:767.98px)');

  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const colList = [
    <div className={style.col}>
      <Title.H1 second white>
        Maximizing Yields with yTAO in the Bittensor Ecosystem
      </Title.H1>
    </div>,
    <div className={style.col}>
      <div className={style.footerBody__menu}>
        <Text secondary white className={style.footerBody__menuTitle}>
          Menu:
        </Text>
        <ul className={style.footerBody__menuList}>
          <li>
            <a href=''>Bridge</a>
          </li>
          <li>
            <a href=''>Documentation</a>
          </li>
          <li>
            <a href=''>Rewards</a>
          </li>
          <li>
            <a href=''>How it works</a>
          </li>
        </ul>
      </div>
    </div>,
    <div className={style.col}>
      <a
        href='https://www.tainetdeck.finance/'
        target='_blank'
        rel='noreferrer'
        className={style.footerBody__whitepaper}
      >
        <div className={style.footerBody__whitepaperBg}>
          <img src={whBg} alt='' />
        </div>
        <Title.H3>Go to</Title.H3>
        <div className={style.footerBody__whitepaperTitle}>Pitchdeck</div>
        <div className={style.footerBody__whitepaperIcon}>{whitepaperIcon}</div>
      </a>
    </div>,
    <div className={style.col}>
      <ul className={style.footerBody__social}>
        {siteContent.social.map((item, index) => (
          <li key={index}>
            <a href={item.href} className='social'>
              {item.name === 'linkedIn' && (
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g clip-path='url(#clip0_607_1581)'>
                    <path
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M2.22222 20H17.7778C19.0051 20 20 19.0051 20 17.7778V2.22222C20 0.994923 19.0051 0 17.7778 0H2.22222C0.994923 0 0 0.994923 0 2.22222V17.7778C0 19.0051 0.994923 20 2.22222 20Z'
                      fill='#090802'
                    />
                    <path
                      className='inverse'
                      fill-rule='evenodd'
                      clip-rule='evenodd'
                      d='M17.2223 17.2223H14.2544V12.1673C14.2544 10.7814 13.7278 10.0069 12.6308 10.0069C11.4374 10.0069 10.814 10.8129 10.814 12.1673V17.2223H7.95376V7.59265H10.814V8.88976C10.814 8.88976 11.674 7.29845 13.7174 7.29845C15.76 7.29845 17.2223 8.54574 17.2223 11.1254V17.2223ZM4.54154 6.33172C3.56729 6.33172 2.77783 5.53607 2.77783 4.55478C2.77783 3.57349 3.56729 2.77783 4.54154 2.77783C5.51579 2.77783 6.30478 3.57349 6.30478 4.55478C6.30478 5.53607 5.51579 6.33172 4.54154 6.33172ZM3.06465 17.2223H6.04711V7.59265H3.06465V17.2223Z'
                      fill='#ECECEC'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_607_1581'>
                      <rect width='20' height='20' fill='white' />
                    </clipPath>
                  </defs>
                </svg>
              )}
              {item.name === 'Bridge' && (
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g clip-path='url(#clip0_501_30941)'>
                    <path
                      d='M3.50879 0.701172H16.4912V19.2977H14.5856V10.7793H14.5669C14.3562 8.42499 12.392 6.58005 10 6.58005C7.60802 6.58005 5.64379 8.42499 5.43315 10.7793H5.41447V19.2977H3.50879V0.701172Z'
                      fill='#141414'
                    />
                    <path
                      d='M0 3.33398L0.780244 5.9748H1.44044V16.6581C1.10896 16.6581 0.840257 16.9268 0.840257 17.2583V17.9785H0.720231C0.388758 17.9785 0.120025 18.2472 0.120025 18.5787V19.2989H6.84211V18.5787C6.84211 18.2472 6.5734 17.9785 6.24193 17.9785H6.1219V17.2583C6.1219 16.9268 5.85317 16.6581 5.5217 16.6581H4.80149V3.33398H0Z'
                      fill='#141414'
                    />
                    <path
                      d='M14.6534 16.6581C14.3219 16.6581 14.0532 16.9268 14.0532 17.2583V17.9785H13.9332C13.6017 17.9785 13.333 18.2472 13.333 18.5787V19.2989H20.0551V18.5787C20.0551 18.2472 19.7864 17.9785 19.4549 17.9785H19.3349V17.2583C19.3349 16.9268 19.0662 16.6581 18.7347 16.6581V5.9748H19.3949L20.1751 3.33398H15.3736V16.6581H14.6534Z'
                      fill='#141414'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_501_30941'>
                      <rect width='20' height='20' fill='white' />
                    </clipPath>
                  </defs>
                </svg>
              )}
              {item.name !== 'Bridge' && item.name !== 'linkedIn' && item.icon}
            </a>
          </li>
        ))}
      </ul>
    </div>,
  ];

  return (
    <footer className={`${style.footer} footer`}>
      <div className='__container'>
        <div className={style.footerTop}>
          {!isMobile && (
            <div className={style.footerTop__logo}>
              <img src={logo} alt='' />
            </div>
          )}
          {isMobile && (
            <a href='' className={style.footerTop__launch}>
              Launch app
            </a>
          )}
          <div onClick={toTop} className={style.footerTop__up}>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M5.92993 9.57031L11.9999 3.50031L18.0699 9.57031'
                stroke='#141414'
                stroke-width='1.5'
                stroke-miterlimit='10'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
              <path
                d='M11.9999 20.5L11.9999 3.67'
                stroke='#141414'
                stroke-width='1.5'
                stroke-miterlimit='10'
                stroke-linecap='round'
                stroke-linejoin='round'
              />
            </svg>
          </div>
        </div>
      </div>
      <div className={style.footerCenter}>
        <div className={style.footer__bg}>
          <img src={bg} alt='' />
        </div>

        <div className='__container'>
          <div className={style.footerBody}>
            {isMobile ? (
              <>
                {colList[0]}
                {colList[2]}
                {colList[1]}
                {colList[3]}
              </>
            ) : (
              <>
                <div className={style.row}>
                  {colList[0]}
                  {colList[1]}
                </div>
                <div className={style.row}>
                  {colList[2]}
                  {colList[3]}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={style.footerBottom}>
        <div className='__container'>
          {isMobile && (
            <div className={style.footerBottom__links}>
              <Text secondary white>
                <a href=''>Privacy Policy</a>
              </Text>
              <Text secondary white>
                <a href=''>Terms of use</a>
              </Text>
            </div>
          )}
          <Text secondary white>
            © 2024 Advisors Portugal. All Rights Reserved.
          </Text>
          {!isMobile && (
            <>
              <Text className={style.footerBottom__watermark} secondary white>
                Designed by <a href=''>Demyanchuk Art</a>
              </Text>
              <Text secondary white>
                <a href=''>Terms and Conditions | Privacy Policy</a>
              </Text>
            </>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
