const lines2 = (
  <svg
    width='320'
    height='72'
    viewBox='0 0 320 72'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0.5 71V71C14.4626 71 27.7271 64.8945 36.8075 54.2879L52.25 36.25C71.1313 14.1954 98.7124 1.5 127.745 1.5H319.5'
      stroke='#141414'
      stroke-width='1.5'
    />
  </svg>
);

export default lines2;
