import twitterIcon from './assets/icons/twitter';
import linkedInIcon from './assets/icons/linkedin';
import bridgeIcon from './assets/icons/bridge';
import telegramIcon from './assets/icons/telegram';
import discordIcon from './assets/icons/discord';

export const siteContent = {
   social: [
      {
         name: 'Twitter',
         href: '',
         icon: twitterIcon,
      },
      {
         name: 'linkedIn',
         href: '',
         icon: linkedInIcon,
      },
      {
         name: 'Bridge',
         href: '',
         icon: bridgeIcon,
      },
      {
         name: 'Telegram',
         href: '',
         icon: telegramIcon,
      },
      {
         name: 'Discord',
         href: '',
         icon: discordIcon,
      },
   ],
};
