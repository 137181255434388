const twitterIcon = (
   <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
   >
      <path
         d='M15.2718 1.58643H18.0831L11.9413 8.606L19.1666 18.1581H13.5093L9.07828 12.3648L4.00821 18.1581H1.19528L7.76445 10.6498L0.833252 1.58643H6.63418L10.6394 6.8817L15.2718 1.58643ZM14.2852 16.4754H15.8429L5.78775 3.1807H4.11614L14.2852 16.4754Z'
         fill='#141414'
      />
   </svg>
);
export default twitterIcon;
