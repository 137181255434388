const content = {
  cards: [
    {
      title: 'yTAO Mint Fee',
      text: 'Users are charged a 1% minting fee when depositing their wTAO for yTAO. This fee is utilized to cover gas costs and ongoing upkeep and operational expenses.',
    },
    {
      title: 'yTAO Redemption Fee',
      text: 'Should a user wish to unstake their yTAO and covert it back to wTAO they incur a 1% fee. This is typically favorable vs. paying trading fees and incurring any slippage on a DEX and enables the protocol to diver that revenue back into the growth of the ecosystem.',
    },
    {
      title: 'yTAO Yield Fee',
      text: 'The protocol takes a 10% fee on yield generated. Much like a VC takes a performance fee for optimizing returns, so does TaiNet take a fee that is diverted back into optimizing our algorithms to run our BTA and stay ahead of the competition, ensuring our users achieve best in class yields.',
    },
    {
      title: 'TAI Trading Fees',
      text: 'TAI operates with a 3/3 trading tax (3% tax on buys and sells). As the protocol fair launched with minimal funding this ensure the protocol is able to operate and grow and in due course acquire its own subnet. This tax also ensures the requisite levels of decentralization and equitable access vs. behind closed doors VC raises.',
    },
    {
      title: 'xTAI Early Unstake Penalty',
      text: 'xTAI stakers must commit to a 30 day staking period. They may withdraw early ahead of the 30 day period but will be subject to a 10% early withdrawal penalty. This is to encourage a longer term engagement, as the early withdrawal penalty is recycled back into the incentives pool for long term participants in the ecosystem.',
    },
  ],
};
export default content;
