const whitepaperIcon = (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9 22.75H7C2.59 22.75 1.25 21.41 1.25 17V7C1.25 2.59 2.59 1.25 7 1.25H8.5C10.25 1.25 10.8 1.82 11.5 2.75L13 4.75C13.33 5.19 13.38 5.25 14 5.25H17C21.41 5.25 22.75 6.59 22.75 11V13C22.75 13.41 22.41 13.75 22 13.75C21.59 13.75 21.25 13.41 21.25 13V11C21.25 7.43 20.58 6.75 17 6.75H14C12.72 6.75 12.3 6.30999 11.8 5.64999L10.3 3.64999C9.77999 2.95999 9.62 2.75 8.5 2.75H7C3.42 2.75 2.75 3.43 2.75 7V17C2.75 20.57 3.42 21.25 7 21.25H9C9.41 21.25 9.75 21.59 9.75 22C9.75 22.41 9.41 22.75 9 22.75Z'
      fill='#141414'
    />
    <path
      d='M19.3403 22.811C19.3303 22.811 19.3202 22.811 19.3102 22.811H13.7502C12.0802 22.691 11.2402 21.411 11.2402 20.191C11.2402 19.211 11.7902 18.181 12.8502 17.761C12.6302 16.511 12.9503 15.351 13.7803 14.481C14.8003 13.411 16.4402 12.981 17.8602 13.411C19.1002 13.791 19.9902 14.771 20.3602 16.131C21.4102 16.451 22.2403 17.281 22.5803 18.411C22.9803 19.721 22.6102 21.061 21.6202 21.921C21.0202 22.491 20.2003 22.811 19.3403 22.811ZM13.7902 19.071C13.0302 19.141 12.7502 19.711 12.7502 20.191C12.7502 20.671 13.0302 21.2509 13.8102 21.311H19.3203C19.8003 21.351 20.2803 21.131 20.6403 20.811C21.2903 20.241 21.3302 19.431 21.1602 18.851C20.9902 18.271 20.4903 17.621 19.6503 17.511C19.3203 17.471 19.0602 17.221 19.0002 16.891C18.7802 15.541 18.0202 15.041 17.4302 14.851C16.5502 14.581 15.5102 14.8609 14.8802 15.5209C14.4502 15.9709 14.0302 16.781 14.5002 18.071C14.6402 18.461 14.4402 18.891 14.0502 19.031C13.9602 19.061 13.8702 19.071 13.7902 19.071Z'
      fill='#141414'
    />
  </svg>
);

export default whitepaperIcon;
