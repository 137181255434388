import Title from '../../components/Title';
import style from './Strategies.module.scss';
import coin from './assets/img/coin.png';
import coin2 from './assets/img/coin-2.png';
import coin3 from './assets/img/coin-3.png';
import Text from '../../components/Text';
import content from './content';
import { useMediaQuery } from 'usehooks-ts';
import usdtIcon from './assets/img/usdt-icon.svg';
import taoIcon from './assets/img/tao-icon.svg';

const StrategiesSection = () => {
  const isMobile = useMediaQuery('(max-width:767.98px)');

  return (
    <section className={style.strategies}>
      <div className='__container'>
        <div className={style.strategiesCoins}>
          <div className={style.strategiesCoins__item}>
            <img src={coin2} alt='' />
          </div>
          <div className={style.strategiesCoins__item}>
            <img src={isMobile ? coin3 : coin} alt='' />
          </div>
        </div>

        <div className={style.strategiesTop}>
          <Title.H2 className={style.strategiesTop__title}>
            Using AI-Driven Strategies <span>To Optimize TAO</span> Staking
            <span>Yields</span>
          </Title.H2>
          <div className={style.strategiesTop__cards}>
            <div className={style.card}>
              <Title.H3 className={style.card__title}>Totall stake</Title.H3>
              <div className={style.card__icon}>
                <img src={usdtIcon} alt='' />
              </div>
              <Title.H3 className={style.card__price}>$1.21</Title.H3>
              <Text secondary className={style.card__label}>
                100 matic
              </Text>
            </div>
            <div className={style.card}>
              <Title.H3 className={style.card__title}>Total Staked</Title.H3>
              <div className={style.card__icon}>
                <img src={taoIcon} alt='' />
              </div>
              <Title.H3 className={style.card__price}>$53.435.23</Title.H3>
              <Text secondary className={style.card__label}>
                1000 TAO
              </Text>
            </div>
            <div className={style.card}>
              <Title.H3 className={style.card__title}>Totall stake</Title.H3>
              <div className={style.card__icon}>
                <img src={usdtIcon} alt='' />
              </div>
              <Title.H3 className={style.card__price}>$1.21</Title.H3>
              <Text secondary className={style.card__label}>
                100 matic
              </Text>
            </div>
          </div>
          {/* <div className={style.strategiesTop__img}>
            <img src={img} alt='' />
          </div> */}
        </div>
        <div className={style.strategiesBody}>
          {!isMobile && (
            <>
              <div className={style.row}>
                {content.cards.map((item, index) => (
                  <div className={style.col} key={index}>
                    <div className={style.col__icon}>
                      <img src={item.icon} alt='' />
                    </div>
                    <Title.H3 className={style.col__title}>
                      {item.title}
                    </Title.H3>
                  </div>
                ))}
              </div>
              <div className={style.row}>
                {content.cards.map((item, index) => (
                  <div key={index} className={style.col}>
                    <Text secondary className={style.col__text}>
                      {item.text}
                    </Text>
                  </div>
                ))}
              </div>
            </>
          )}
          {isMobile &&
            content.cards.map((item, index) => (
              <div key={index} className={style.strategiesBody__item}>
                <div className={style.strategiesBody__itemIcon}>
                  <img src={item.icon} alt='' />
                </div>
                <Title.H3 className={style.strategiesBody__itemTitle}>
                  {item.title}
                </Title.H3>
                <Text secondary className={style.strategiesBody__itemText}>
                  {item.text}
                </Text>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default StrategiesSection;
