import { useMediaQuery } from 'usehooks-ts';
import style from './Header.module.scss';
import logo from './assets/img/logo.svg';
import logoMobile from './assets/img/logo-mobile.svg';
import burgerIcon from './assets/img/burger';
import closeIcon from './assets/img/close';
import { useState } from 'react';
import { siteContent } from './../../siteContent';
import hoverCircle from './assets/icons/hover-circle';

const Header = () => {
  const isMobile = useMediaQuery('(max-width:767.98px)');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (isMenuOpen) {
      document.body.classList.remove('_lock');
    } else {
      document.body.classList.add('_lock');
    }
  };

  return (
    <header className={style.header}>
      <div className='__container'>
        <div className={style.headerBody}>
          <div className={style.headerBody__logo}>
            <img src={isMobile ? logoMobile : logo} alt='' />
          </div>
          <nav
            className={`${style.headerBody__nav} ${isMenuOpen && style.open}`}
          >
            <ul>
              <li>
                <span className={`${style.circle} ${style.orange}`}></span>
                Bridge
                {hoverCircle}
              </li>
              <li>
                <a
                  href='https://tainet.gitbook.io/tainet-whitepaper'
                  target='_blank'
                  rel='noreferrer'
                >
                  <span className={`${style.circle} ${style.purple}`}></span>
                  Documentation
                  {hoverCircle}
                </a>
              </li>
              <li>
                <span className={`${style.circle} ${style.grey}`}></span>
                Rewards
                {hoverCircle}
              </li>
              <li>
                <span className={`${style.circle} ${style.black}`}></span>
                How it works
                {hoverCircle}
              </li>
            </ul>
            {!isMobile && (
              <a href='' className={style.headerBody__navLink}>
                Launch app
              </a>
            )}
            {isMobile && (
              <div className={style.bottom}>
                <div className={style.bottomSocial}>
                  {siteContent.social.map((item, index) => (
                    <a key={index} href={item.href}>
                      {item.icon}
                    </a>
                  ))}
                </div>
                <div className={style.bottomInfo}>
                  <div className={style.bottomInfo__links}>
                    <a href=''>Privacy Policy</a>
                    <a href=''>Terms of use</a>
                  </div>
                  <div className={style.bottomInfo__cr}>
                    © 2023 Music Protocol All rights reserved
                  </div>
                </div>
              </div>
            )}
          </nav>
          {isMobile && (
            <>
              <a href='' className={style.headerBody__navLink}>
                Launch app
              </a>
              <button onClick={handleMenu} className={style.headerBody__burger}>
                {isMenuOpen ? closeIcon : burgerIcon}
              </button>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
