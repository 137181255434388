const circle = (
  <svg
    width='20'
    height='6'
    viewBox='0 0 20 6'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clip-path='url(#clip0_2081_153)'>
      <ellipse cx='10' cy='6.5' rx='7' ry='6.5' fill='#EBEBEB' />
      <circle cx='10' cy='8' r='5' fill='#141414' />
      <path
        d='M0.396372 6C0.270069 6.0046 0.138222 6.00451 0 6H0.396372C1.72167 5.95175 2.43652 5.38748 3.5 4V6H0.396372Z'
        fill='#EBEBEB'
      />
      <path
        d='M4 4.5V3.5C3.80835 3.56327 3.69867 3.67615 3.5 4V4.5H4Z'
        fill='#EBEBEB'
      />
      <path
        d='M19.6036 6C19.7299 6.0046 19.8618 6.00451 20 6H19.6036C18.2783 5.95175 17.5635 5.38748 16.5 4V6H19.6036Z'
        fill='#EBEBEB'
      />
      <path
        d='M16 4.5V3.5C16.1917 3.56327 16.3013 3.67615 16.5 4V4.5H16Z'
        fill='#EBEBEB'
      />
    </g>
    <defs>
      <clipPath id='clip0_2081_153'>
        <rect width='20' height='6' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
export default circle;
