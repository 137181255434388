const closeIcon = (
   <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
   >
      <path
         d='M28 20L20 28M20 20L28 28'
         stroke='#141414'
         stroke-linecap='round'
         stroke-linejoin='round'
      />
   </svg>
);

export default closeIcon;
