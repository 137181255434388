import { useRef, useState } from 'react';
import TextCard from '../../components/TextCard';
import Title from '../../components/Title';
import style from './Staking.module.scss';
import { useLottieLoad } from './../../hooks/useLootie';
import content from './content';
import { useMediaQuery } from 'usehooks-ts';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import SliderNav from '../../components/SliderNav';
import { Navigation } from 'swiper/modules';

const StakingSection = () => {
  const animRef = useRef(null);
  const anim = useLottieLoad({
    ref: animRef,
    path: './assets/lootie/staking-anim.json',
    loop: true,
  });
  const isMobile = useMediaQuery('(max-width:767.98px)');
  const [sliderCounter, setSliderCounter] = useState('0/0');

  return (
    <section className={style.staking}>
      <div className='__container'>
        <Title.H2 className={style.staking__title}>
          TaiNet <span>simplifies staking with</span> native TAO deposits
        </Title.H2>
        <div className={style.stakingBody}>
          {!isMobile && (
            <>
              <div className={style.stakingBody__content}>
                {content.cards.map((item, index) => (
                  <TextCard
                    key={index}
                    title={item.title}
                    text={item.text}
                    className={style.stakingBody__contentItem}
                  />
                ))}
              </div>
              <div className={style.stakingBody__anim} ref={anim}></div>
            </>
          )}
          {isMobile && (
            <SliderNav
              prevEl={'staking-mobile-slider-prev'}
              nextEl={'staking-mobile-slider-next'}
              count={sliderCounter}
            />
          )}
          {isMobile && (
            <Swiper
              modules={[Navigation]}
              autoHeight
              className={style.stakingBody__slider}
              spaceBetween={24}
              navigation={{
                prevEl: '#staking-mobile-slider-prev',
                nextEl: '#staking-mobile-slider-next',
              }}
              onSlideChange={(swiper) => {
                setSliderCounter(
                  `${swiper.realIndex + 1}/${swiper.slides.length}`
                );
              }}
              onSwiper={(swiper) => {
                setSliderCounter(
                  `${swiper.realIndex + 1}/${swiper.slides.length}`
                );
              }}
            >
              {content.cards.map((item, index) => (
                <SwiperSlide key={index}>
                  <TextCard
                    title={item.title}
                    text={item.text}
                    className={style.stakingBody__contentItem}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      </div>
    </section>
  );
};

export default StakingSection;
