import Header from './components/Header';
import Footer from './components/Footer';
import MainSection from './sections/Main';
import StakingSection from './sections/Staking';
import StrategiesSection from './sections/Strategies';
import UnlockingSection from './sections/Unlocking';
import AboutSection from './sections/About';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { useRef, useState } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import style from './App.module.scss';
import { useMediaQuery } from 'usehooks-ts';
import DaoSection from './sections/Dao';
import RevenueSection from './sections/Revenue';
import EnhancingSection from './sections/Enhancing';
import PotentialSection from './sections/Potential';
import GrowthSection from './sections/Growth';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

function App() {
  const container = useRef();
  const animWrapperRef = useRef();
  const aboutSectionRef = useRef();
  const isTablet = useMediaQuery('(max-width:991.98px)');
  const [progress, setProgress] = useState(0);
  const containerPotential = useRef();
  const potentialScrollWrapperRef = useRef();
  const potentialScrollBlockRef = useRef();
  const [potentialProgress, setPotentialProgress] = useState(0);

  useGSAP(
    () => {
      if (!isTablet) {
        const calcHeight =
          (window.innerHeight - aboutSectionRef.current.scrollHeight) / 2;

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: container.current,
            // start: '-=10px',
            start: `-=${calcHeight <= 0 ? 10 : calcHeight}`,
            pin: true,
            scrub: 1,
            markers: false,
            onUpdate: (self) => {
              setProgress(self.progress);
            },
          },
        });

        tl.to(animWrapperRef.current, {
          y: '-66%',
        });
      }
    },
    { scope: container }
  );

  useGSAP(
    () => {
      if (!isTablet) {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: containerPotential.current,
            start: 'top top',
            pin: true,
            scrub: 1,
            markers: false,
            onUpdate: (self) => {
              setPotentialProgress(self.progress);
            },
          },
        });

        tl.to(potentialScrollWrapperRef.current, {
          y:
            potentialScrollBlockRef.current.offsetHeight -
            potentialScrollWrapperRef.current.scrollHeight -
            50,
        });
      }
    },
    { scope: containerPotential }
  );

  return (
    <div className='wrapper'>
      <Header />

      <div className='page'>
        <MainSection />
        <StakingSection />
        <StrategiesSection />
        <EnhancingSection />
        <div className={style.aboutCon} ref={container}>
          <AboutSection
            sectionRef={aboutSectionRef}
            progress={progress}
            animWrapperRef={animWrapperRef}
            isTablet={isTablet}
          />
          <UnlockingSection />
        </div>
        <PotentialSection
          progress={potentialProgress}
          scopeRef={containerPotential}
          scrollWrapperRef={potentialScrollWrapperRef}
          scrollBlockRef={potentialScrollBlockRef}
          isTablet={isTablet}
        />
        <RevenueSection />
        <GrowthSection />
        <DaoSection />
      </div>

      <Footer />
    </div>
  );
}

export default App;
