const bridgeIcon = (
   <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
   >
      <g clip-path='url(#clip0_501_3094)'>
         <path
            d='M3.50879 0.701172H16.4912V19.2977H14.5856V10.7793H14.5669C14.3562 8.42499 12.392 6.58005 10 6.58005C7.60802 6.58005 5.64379 8.42499 5.43315 10.7793H5.41447V19.2977H3.50879V0.701172Z'
            fill='#141414'
         />
         <path
            d='M0 3.33398L0.780244 5.9748H1.44044V16.6581C1.10896 16.6581 0.840257 16.9268 0.840257 17.2583V17.9785H0.720231C0.388758 17.9785 0.120025 18.2472 0.120025 18.5787V19.2989H6.84211V18.5787C6.84211 18.2472 6.5734 17.9785 6.24193 17.9785H6.1219V17.2583C6.1219 16.9268 5.85317 16.6581 5.5217 16.6581H4.80149V3.33398H0Z'
            fill='#141414'
         />
         <path
            d='M14.6534 16.6581C14.3219 16.6581 14.0532 16.9268 14.0532 17.2583V17.9785H13.9332C13.6017 17.9785 13.333 18.2472 13.333 18.5787V19.2989H20.0551V18.5787C20.0551 18.2472 19.7864 17.9785 19.4549 17.9785H19.3349V17.2583C19.3349 16.9268 19.0662 16.6581 18.7347 16.6581V5.9748H19.3949L20.1751 3.33398H15.3736V16.6581H14.6534Z'
            fill='#141414'
         />
      </g>
      <defs>
         <clipPath id='clip0_501_3094'>
            <rect width='20' height='20' fill='white' />
         </clipPath>
      </defs>
   </svg>
);
export default bridgeIcon;
