const lines4 = (
  <svg
    width='1024'
    height='104'
    viewBox='0 0 1024 104'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M977 104V84.7844C977 71.1822 986.646 59.4905 1000 56.9058V56.9058C1013.35 54.3211 1023 42.6294 1023 29.0272V7'
      stroke='#141414'
      stroke-width='1.5'
    />
    <path
      d='M47 103.5V81.4854C47 67.8099 37.3928 56.0159 24 53.25V53.25C10.6072 50.4841 1 38.6901 1 25.0146V0'
      stroke='#141414'
      stroke-width='1.5'
    />
  </svg>
);

export default lines4;
