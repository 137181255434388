import icon1 from './assets/icons/icon1.svg';
import icon2 from './assets/icons/icon2.svg';
import icon3 from './assets/icons/icon3.svg';
import icon4 from './assets/icons/icon4.svg';

const content = {
  items: [
    {
      icon: icon1,
      title: 'Belief in Decentralization',
      text: "The project's core contributors strongly advocate for decentralization, recognizing its transformative potential. They understand that decentralized systems empower individuals and communities by distributing control and data access.",
    },
    {
      icon: icon2,
      title: 'AI and Risks of Centralization',
      text: 'While acknowledging the vast opportunities presented by AI, the contributors are also aware of the associated risks, particularly regarding the concentration of control and data. They aim to mitigate these risks as they develop the protocol further.',
    },
    {
      icon: icon3,
      title: 'Building a Robust Protocol',
      text: 'The long-term goal is to develop a robust protocol that operates independently, contributing to the growth and stability of the Bittensor ecosystem. This protocol will generate sustainable revenue while upholding the principles of decentralization.',
    },
    {
      icon: icon4,
      title: 'Formation of Taiji DAO',
      text: "As the protocol matures, the Taiji DAO becomes essential, acting as a decentralized organization to balance capitalism and decentralization. Empowering xTAI stakers, it guides the protocol's direction, prioritizing Bittensor and TaiNet users' interests.",
    },
  ],
};

export default content;
