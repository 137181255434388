import style from './ProgressBar.module.scss';

const ProgressBar = ({ className, progress, count, isSlider }) => {
  const countArr = [];
  for (let index = 0; index < count; index++) {
    countArr.push([]);
  }

  return (
    <div
      className={`${style.progress} ${isSlider && style.slider} ${className}`}
    >
      {countArr.map((item, index) => (
        <ProgressLine
          key={index}
          percents={
            index + 1 <= Math.floor(progress * count)
              ? 100
              : progress * count - index > 0
              ? (progress * count - index) * 100
              : 0
          }
        />
      ))}
    </div>
  );
};

const ProgressLine = ({ percents }) => {
  return (
    <div className={style.progress__line}>
      <span style={{ width: `${percents}%` }}></span>
    </div>
  );
};

export default ProgressBar;
