import icon1 from './assets/img/icon1.svg';
import icon2 from './assets/img/icon2.svg';
import icon3 from './assets/img/icon3.svg';

const content = {
  cards: [
    {
      icon: icon1,
      title: (
        <>
          Maximizing TAO <br /> Staking Yields
        </>
      ),
      text: (
        <>
          In addition to making staked TAO liquid, TaiNet deploys its AI-driven{' '}
          <span>Bittensor Subnet Aggregator (BTA)</span> to optimize yields
        </>
      ),
    },
    {
      icon: icon2,
      title: (
        <>
          Algorithmic Rewards <br /> Optimization:
        </>
      ),
      text: (
        <>
          TaiNet’s algorithms intake data from past emission reweightings to
          predict future activity. This enables them to maximize rewards for
          yTAO holders, delivering greater yields than passive staking
        </>
      ),
    },
    {
      icon: icon3,
      title: (
        <>
          Bittensor Subnet <br /> Aggregation
        </>
      ),
      text: (
        <>
          TaiNet's AI-driven Bittensor Subnet Aggregation deploys TAO holdings
          strategically in real-time.
        </>
      ),
    },
  ],
};
export default content;
