import style from './Title.module.scss';

const Title = {
  cls: style.title,

  H1: ({ children, className, white, second }) => {
    const cls_h1 = `${Title.cls} ${style.h1} ${
      white && style.white
    } ${className} `;

    return second ? (
      <h1 className={cls_h1}>{children}</h1>
    ) : (
      <h2 className={cls_h1}>{children}</h2>
    );
  },
  H2: ({ children, white, className }) => {
    return (
      <h2
        className={`${Title.cls} ${style.h2} ${
          white && style.white
        } ${className}`}
      >
        {children}
      </h2>
    );
  },
  H3: ({ children, white, className }) => {
    return (
      <h3
        className={`${Title.cls} ${style.h3} ${
          white && style.white
        } ${className}`}
      >
        {children}
      </h3>
    );
  },
};

export default Title;
