const telegramIcon = (
   <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
   >
      <path
         d='M18.4543 2.62034C18.1543 2.36594 17.6831 2.32954 17.1959 2.52514H17.1951C16.6827 2.73074 2.69074 8.73234 2.12114 8.97754C2.01754 9.01354 1.11274 9.35114 1.20594 10.1031C1.28914 10.7811 2.01634 11.0619 2.10514 11.0943L5.66234 12.3123C5.89834 13.0979 6.76834 15.9963 6.96074 16.6155C7.08074 17.0015 7.27634 17.5087 7.61914 17.6131C7.91994 17.7291 8.21914 17.6231 8.41274 17.4711L10.5875 15.4539L14.0983 18.1919L14.1819 18.2419C14.4203 18.3475 14.6487 18.4003 14.8667 18.4003C15.0351 18.4003 15.1967 18.3687 15.3511 18.3055C15.8771 18.0895 16.0875 17.5883 16.1095 17.5315L18.7319 3.90074C18.8919 3.17274 18.6695 2.80234 18.4543 2.62034ZM8.79954 12.7995L7.59954 15.9995L6.39954 11.9995L15.5995 5.19954L8.79954 12.7995Z'
         fill='#141414'
      />
   </svg>
);
export default telegramIcon;
