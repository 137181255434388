const linkedInIcon = (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clip-path='url(#clip0_607_158)'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M2.22222 20H17.7778C19.0051 20 20 19.0051 20 17.7778V2.22222C20 0.994923 19.0051 0 17.7778 0H2.22222C0.994923 0 0 0.994923 0 2.22222V17.7778C0 19.0051 0.994923 20 2.22222 20Z'
        fill='#090802'
      />
      <path
        className='inverse'
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M17.2223 17.2223H14.2544V12.1673C14.2544 10.7814 13.7278 10.0069 12.6308 10.0069C11.4374 10.0069 10.814 10.8129 10.814 12.1673V17.2223H7.95376V7.59265H10.814V8.88976C10.814 8.88976 11.674 7.29845 13.7174 7.29845C15.76 7.29845 17.2223 8.54574 17.2223 11.1254V17.2223ZM4.54154 6.33172C3.56729 6.33172 2.77783 5.53607 2.77783 4.55478C2.77783 3.57349 3.56729 2.77783 4.54154 2.77783C5.51579 2.77783 6.30478 3.57349 6.30478 4.55478C6.30478 5.53607 5.51579 6.33172 4.54154 6.33172ZM3.06465 17.2223H6.04711V7.59265H3.06465V17.2223Z'
        fill='#ECECEC'
      />
    </g>
    <defs>
      <clipPath id='clip0_607_158'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
export default linkedInIcon;
