import ProgressBar from '../../components/ProgressBar';
import Text from '../../components/Text';
import Title from '../../components/Title';
import style from './Enhancing.module.scss';
import elem from './assets/img/elem.svg';
import elemMob from './assets/img/elem-mob.svg';
import content from './content';
import linesSvg from './assets/img/lines';
import lines1Svg from './assets/img/lines-1';
import lines2Svg from './assets/img/lines-2';
import lines3Svg from './assets/img/lines-3';
import lines4Svg from './assets/img/lines-4';
import iconCenter from './assets/img/icon-center.svg';
import iconT from './assets/img/icon-t.svg';
import icon1 from './assets/img/icon1.svg';
import icon2 from './assets/img/icon2.svg';
import icon3 from './assets/img/icon3.svg';
import icon4 from './assets/img/icon4.svg';
import icon5 from './assets/img/icon5.svg';
import icon6 from './assets/img/icon6.svg';
import icon7 from './assets/img/icon7.svg';
import { useGSAP } from '@gsap/react';
import { useMediaQuery } from 'usehooks-ts';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useEffect, useRef, useState } from 'react';
import anime from 'animejs';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

const EnhancingSection = () => {
  const container = useRef();
  const linesRef = useRef();
  const isTablet = useMediaQuery('(max-width:991.98px)');
  const [progress, setProgress] = useState(0);
  const [sliderProgress, setSliderProgress] = useState(0);
  const [activeIndex, setActiveIndex] = useState(1);
  const [isFinish, setIsFinish] = useState(false);

  useGSAP(
    () => {
      if (!isTablet) {
        const linesArr = linesRef.current.querySelectorAll(`svg:not(.nodraw)`);
        const paths2 = linesArr[0].querySelectorAll('path');
        const paths3 = linesArr[1].querySelectorAll('path');
        const paths4 = linesArr[2].querySelectorAll('path');

        const paths2Anim = anime({
          targets: paths2,
          duration: 3000,
          autoplay: false,
          easing: 'easeInQuad',
          loop: false,
          strokeDashoffset: [anime.setDashoffset, 0],
        });
        const paths3Anim = anime({
          targets: paths3,
          duration: 3000,
          autoplay: false,
          easing: 'easeInQuad',
          loop: false,
          strokeDashoffset: [anime.setDashoffset, 0],
        });
        const paths4Anim = anime({
          targets: paths4,
          duration: 3000,
          autoplay: false,
          easing: 'easeInQuad',
          loop: false,
          strokeDashoffset: [anime.setDashoffset, 0],
        });

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: container.current,
            start: 'top top',
            pin: true,
            scrub: 1,
            markers: false,
            onUpdate: (self) => {
              setProgress(self.progress);
              // paths2Anim.seek(self.progress * paths2Anim.duration);
              // progress * count - index
              paths2Anim.seek((self.progress * 3 - 0) * paths2Anim.duration);
              paths3Anim.seek((self.progress * 3 - 1) * paths3Anim.duration);
              paths4Anim.seek((self.progress * 3 - 2) * paths4Anim.duration);

              if (self.progress >= 0.98) {
                setIsFinish(true);
              } else {
                setIsFinish(false);
              }
            },
          },
        });
      }
    },
    { scope: container }
  );

  useEffect(() => {
    if (progress >= 0 && progress < 0.33) {
      setActiveIndex(0);
    }
    if (progress >= 0.33 && progress <= 0.66) {
      setActiveIndex(1);
    }
    if (progress > 0.66) {
      setActiveIndex(2);
    }
  }, [progress]);

  return (
    <section ref={container} className={style.enhancing}>
      <div className='__container'>
        <div className={style.enhancingBody}>
          <div className={style.enhancingBody__elem}>
            <img src={isTablet ? elemMob : elem} alt='' />
          </div>
          {!isTablet && (
            <ProgressBar
              className={style.enhancingBody__progress}
              progress={progress}
              count={3}
            />
          )}
          <div className={style.enhancingBody__content}>
            <Title.H2 className={style.enhancingBody__title}>
              Enhancing Yield <span>Optimization through Competitive</span>{' '}
              Strategy
            </Title.H2>
            {isTablet && (
              <ProgressBar
                className={style.enhancingBody__progress}
                progress={sliderProgress}
                isSlider
                count={3}
              />
            )}
            {isTablet && (
              <Swiper
                className={style.enhancingSlider}
                autoHeight
                spaceBetween={30}
                onSwiper={(e) => {
                  setSliderProgress((e.realIndex + 1) / e.slides.length);
                }}
                onSlideChange={(e) => {
                  setSliderProgress((e.realIndex + 1) / e.slides.length);
                }}
              >
                {content.cards.map((item, index) => (
                  <SwiperSlide key={index}>
                    <Text
                      className={`${style.enhancingBody__text} ${style.active}`}
                      secondary
                    >
                      {item.text}
                    </Text>
                    <div className={style.enhancingSlider__roadmap}>
                      <img src={item.img} alt='' />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
            {!isTablet && (
              <>
                <div className={style.enhancingBody__contentWrapper}>
                  {content.cards.map((item, index) => (
                    <Text
                      key={index}
                      className={`${style.enhancingBody__text} ${
                        activeIndex === index && style.active
                      }`}
                    >
                      {item.text}
                    </Text>
                  ))}
                </div>
                <div className={style.enhancingBody__roadmap}>
                  <div
                    className={`${style.enhancingBody__roadmapItem} ${style.active}`}
                  >
                    <div className={`${style.circle} ${style.top}`}>
                      <svg
                        width='15'
                        height='15'
                        viewBox='0 0 15 15'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <circle
                          cx='7.3906'
                          cy='7.39065'
                          r='6.64065'
                          transform='rotate(90 7.3906 7.39065)'
                          fill='#EBEBEB'
                          stroke='#141414'
                          stroke-width='1.5'
                        />
                        <circle
                          cx='7.39086'
                          cy='7.38966'
                          r='3.69533'
                          transform='rotate(90 7.39086 7.38966)'
                          fill='#141414'
                        />
                      </svg>
                    </div>
                    <img src={iconCenter} alt='' />
                  </div>
                  <div
                    className={`${style.enhancingBody__roadmapItem} ${style.active}`}
                  >
                    <div className={`${style.circle} ${style.left}`}>
                      <svg
                        width='15'
                        height='15'
                        viewBox='0 0 15 15'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <circle
                          cx='7.3906'
                          cy='7.39065'
                          r='6.64065'
                          transform='rotate(90 7.3906 7.39065)'
                          fill='#EBEBEB'
                          stroke='#141414'
                          stroke-width='1.5'
                        />
                        <circle
                          cx='7.39086'
                          cy='7.38966'
                          r='3.69533'
                          transform='rotate(90 7.39086 7.38966)'
                          fill='#141414'
                        />
                      </svg>
                    </div>
                    <img src={iconT} alt='' />
                  </div>
                  <div
                    className={`${style.enhancingBody__roadmapItem} ${style.active}`}
                  >
                    <div className={`${style.circle} ${style.right}`}>
                      <svg
                        width='15'
                        height='15'
                        viewBox='0 0 15 15'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <circle
                          cx='7.3906'
                          cy='7.39065'
                          r='6.64065'
                          transform='rotate(90 7.3906 7.39065)'
                          fill='#EBEBEB'
                          stroke='#141414'
                          stroke-width='1.5'
                        />
                        <circle
                          cx='7.39086'
                          cy='7.38966'
                          r='3.69533'
                          transform='rotate(90 7.39086 7.38966)'
                          fill='#141414'
                        />
                      </svg>
                    </div>
                    <img src={iconT} alt='' />
                  </div>
                  <div
                    className={`${style.enhancingBody__roadmapItem} ${
                      activeIndex >= 1 && style.active
                    }`}
                  >
                    <div className={`${style.circle} ${style.top}`}>
                      <svg
                        width='15'
                        height='15'
                        viewBox='0 0 15 15'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <circle
                          cx='7.3906'
                          cy='7.39065'
                          r='6.64065'
                          transform='rotate(90 7.3906 7.39065)'
                          fill='#EBEBEB'
                          stroke='#141414'
                          stroke-width='1.5'
                        />
                        <circle
                          cx='7.39086'
                          cy='7.38966'
                          r='3.69533'
                          transform='rotate(90 7.39086 7.38966)'
                          fill='#141414'
                        />
                      </svg>
                    </div>
                    <div className={`${style.circle} ${style.left}`}>
                      <svg
                        width='15'
                        height='15'
                        viewBox='0 0 15 15'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <circle
                          cx='7.3906'
                          cy='7.39065'
                          r='6.64065'
                          transform='rotate(90 7.3906 7.39065)'
                          fill='#EBEBEB'
                          stroke='#141414'
                          stroke-width='1.5'
                        />
                        <circle
                          cx='7.39086'
                          cy='7.38966'
                          r='3.69533'
                          transform='rotate(90 7.39086 7.38966)'
                          fill='#141414'
                        />
                      </svg>
                    </div>
                    <img src={iconT} alt='' />
                  </div>
                  <div
                    className={`${style.enhancingBody__roadmapItem} ${
                      activeIndex >= 2 && style.active
                    }`}
                  >
                    <div className={`${style.circle} ${style.top}`}>
                      <svg
                        width='15'
                        height='15'
                        viewBox='0 0 15 15'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <circle
                          cx='7.3906'
                          cy='7.39065'
                          r='6.64065'
                          transform='rotate(90 7.3906 7.39065)'
                          fill='#EBEBEB'
                          stroke='#141414'
                          stroke-width='1.5'
                        />
                        <circle
                          cx='7.39086'
                          cy='7.38966'
                          r='3.69533'
                          transform='rotate(90 7.39086 7.38966)'
                          fill='#141414'
                        />
                      </svg>
                    </div>
                    <div className={`${style.circle} ${style.right}`}>
                      <svg
                        width='15'
                        height='15'
                        viewBox='0 0 15 15'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <circle
                          cx='7.3906'
                          cy='7.39065'
                          r='6.64065'
                          transform='rotate(90 7.3906 7.39065)'
                          fill='#EBEBEB'
                          stroke='#141414'
                          stroke-width='1.5'
                        />
                        <circle
                          cx='7.39086'
                          cy='7.38966'
                          r='3.69533'
                          transform='rotate(90 7.39086 7.38966)'
                          fill='#141414'
                        />
                      </svg>
                    </div>
                    <img src={iconT} alt='' />
                  </div>
                  <div
                    className={`${style.enhancingBody__roadmapItem} ${
                      isFinish && style.active
                    }`}
                  >
                    <div className={`${style.circle} ${style.bottom}`}>
                      <svg
                        width='15'
                        height='15'
                        viewBox='0 0 15 15'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <circle
                          cx='7.3906'
                          cy='7.39065'
                          r='6.64065'
                          transform='rotate(90 7.3906 7.39065)'
                          fill='#EBEBEB'
                          stroke='#141414'
                          stroke-width='1.5'
                        />
                        <circle
                          cx='7.39086'
                          cy='7.38966'
                          r='3.69533'
                          transform='rotate(90 7.39086 7.38966)'
                          fill='#141414'
                        />
                      </svg>
                    </div>
                    <img src={iconT} alt='' />
                  </div>
                  <div
                    className={`${style.enhancingBody__roadmapItem} ${
                      isFinish && style.active
                    }`}
                  >
                    <div className={`${style.circle} ${style.bottom}`}>
                      <svg
                        width='15'
                        height='15'
                        viewBox='0 0 15 15'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <circle
                          cx='7.3906'
                          cy='7.39065'
                          r='6.64065'
                          transform='rotate(90 7.3906 7.39065)'
                          fill='#EBEBEB'
                          stroke='#141414'
                          stroke-width='1.5'
                        />
                        <circle
                          cx='7.39086'
                          cy='7.38966'
                          r='3.69533'
                          transform='rotate(90 7.39086 7.38966)'
                          fill='#141414'
                        />
                      </svg>
                    </div>
                    <img src={iconT} alt='' />
                  </div>

                  <div
                    ref={linesRef}
                    className={style.enhancingBody__roadmapLines}
                  >
                    {/* {linesSvg} */}
                    {lines1Svg}
                    {lines2Svg}
                    {lines3Svg}
                    {lines4Svg}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EnhancingSection;
