import style from './Text.module.scss';

const Text = ({ children, secondary, sm, white, className }) => {
  return (
    <p
      className={`${style.text} 
         ${secondary && style.secondary} 
         ${sm && style.sm} 
         ${white && style.white} 
         ${className}`}
    >
      {children}
    </p>
  );
};

export default Text;
