const lines3 = (
  <svg
    width='314'
    height='72'
    viewBox='0 0 314 72'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M314 71V71C300.269 71 287.238 64.9403 278.39 54.4405L263.061 36.25C244.493 14.2163 217.148 1.5 188.334 1.5H4.41074e-06'
      stroke='#141414'
      stroke-width='1.5'
    />
  </svg>
);

export default lines3;
