const lines1 = (
  <svg
    className='nodraw'
    width='573'
    height='114'
    viewBox='0 0 573 114'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M286 113.5V80C286 36.3695 321.37 1 365 1H572.5'
      stroke='#141414'
      stroke-width='1.5'
    />
    <path
      d='M286 114V80C286 36.3695 250.63 1 207 1H6.67572e-06'
      stroke='#141414'
      stroke-width='1.5'
    />
  </svg>
);

export default lines1;
