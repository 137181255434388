const arrowIcon = (
   <svg
      width='34'
      height='34'
      viewBox='0 0 34 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
   >
      <path
         d='M14.426 10.9895L23.0103 10.9895L23.0103 19.5738'
         stroke='white'
         stroke-width='1.5'
         stroke-miterlimit='10'
         stroke-linecap='round'
         stroke-linejoin='round'
      />
      <path
         d='M10.9895 23.0105L22.8901 11.1099'
         stroke='white'
         stroke-width='1.5'
         stroke-miterlimit='10'
         stroke-linecap='round'
         stroke-linejoin='round'
      />
   </svg>
);
export default arrowIcon;
