import { useEffect, useState } from 'react';
import Lottie from 'lottie-web';

export function useLottieLoad({
  ref,
  path,
  renderer = 'svg',
  loop = true,
  autoplay = true,
}) {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (ref.current && isInitialized === false) {
      setIsInitialized(true);
      Lottie.loadAnimation({
        container: ref.current,
        renderer,
        loop,
        autoplay,
        path: `${path}`,
      });
    }
  }, [ref, isInitialized]);

  return ref;
}
