import img1 from './assets/img/img-mob-1.svg';
import img2 from './assets/img/img-mob-2.svg';
import img3 from './assets/img/img-mob-3.svg';

const content = {
  cards: [
    {
      img: img1,
      text: 'Bittensor subnets are in a constant state of competition, seeking to acquire a greater effective stake and therefore a greater impact on the network. Subnets/delegates that fail to reward delegators adequately will see their effective stake drop dramatically, causing them to bleed delegators and leading to the ultimate decline of the subnet itself.',
    },
    {
      img: img2,
      text: 'As a protocol dedicated to acquiring and delegating TAO, upon reaching sufficient scale, our BSA may have the ability to uplift less delegated subnets, while also controlling the majority of that subnet’s effective stake. This will result in the BSA accruing the majority of the client subnet’s validator rewards. These types of aggressive strategies can provide a substantial edge over more passive delegators.',
    },
    {
      img: img3,
      text: 'However, in earlier stages, the BSA will focus on delegating to subnets with existing network dominance, or existing revenue accrual models for their applications,  ensuring that yTAO holders acquire the highest possible rewards.',
    },
  ],
};

export default content;
