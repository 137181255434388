import { useMediaQuery } from 'usehooks-ts';
import Text from '../../components/Text';
import Title from '../../components/Title';
import style from './Dao.module.scss';
import img from './assets/img/img.png';
import elem1 from './assets/img/elem-1.png';
import elem2 from './assets/img/elem-2.png';
import content from './content';

const DaoSection = () => {
  const isTablet = useMediaQuery('(max-width:991.98px)');

  return (
    <section className={style.dao}>
      <div className={'__container'}>
        {isTablet && (
          <div className={style.daoElements}>
            <div className={style.daoElements__item}>
              <img src={elem1} alt='' />
            </div>
            <div className={style.daoElements__item}>
              <img src={elem2} alt='' />
            </div>
          </div>
        )}

        {!isTablet && (
          <div className={style.dao__img}>
            <img src={img} alt='' />
          </div>
        )}
        <Title.H2 className={style.dao__title}>
          The TaiNet DAO <span>Balances Decentralization and</span> Capitalism
        </Title.H2>
        <div className={style.daoBody}>
          {isTablet && (
            <>
              {content.items.map((item, index) => (
                <div key={index} className={style.daoBody__item}>
                  <div className={style.daoBody__itemIcon}>
                    <img src={item.icon} alt='' />
                  </div>
                  <Title.H3 className={style.daoBody__itemTitle}>
                    {item.title}
                  </Title.H3>
                  <Text className={style.daoBody__itemText} secondary>
                    {item.text}
                  </Text>
                </div>
              ))}
            </>
          )}
          {!isTablet && (
            <>
              <div className={style.row}>
                {content.items.map((item, index) => (
                  <div key={index} className={style.daoBody__item}>
                    <div className={style.daoBody__itemIcon}>
                      <img src={item.icon} alt='' />
                    </div>
                    <Title.H3 className={style.daoBody__itemTitle}>
                      {item.title}
                    </Title.H3>
                  </div>
                ))}
              </div>
              <div className={style.row}>
                {content.items.map((item, index) => (
                  <div key={index} className={style.daoBody__item}>
                    <Text className={style.daoBody__itemText} secondary>
                      {item.text}
                    </Text>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default DaoSection;
