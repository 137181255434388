const content = {
  cards: [
    {
      title: 'Incentives',
      text: 'The emissions allocation is the maximum total amount of tokens that will be allocated via emissions over an extended period to bootstrap yTAO staking yields, reward sTAI stakers, incentivize liquidity and other activities for keeping the TaiNet ecosystem in balance.',
    },
    {
      title: 'Initial Liquidity',
      text: 'This portion is used to seed initial TAI Liquidity Pools (LP) during the TAI fair launch Token Generation Event (TGE) on Uniswap.',
    },
    {
      title: 'Treasury',
      text: 'The investor reserve is used for the initial TaiNet funding rounds, with any unused allocations earmarked for any future funding requirements that might come online including financing for operating our own subnet at a later date.',
    },
  ],
};
export default content;
