import Text from '../../components/Text';
import Title from '../../components/Title';
import style from './Revenue.module.scss';
import lines from './assets/img/lines.svg';
import linesMob from './assets/img/lines-mobile.svg';
import { useState } from 'react';
import clip from './assets/img/clip.svg';
import { useMediaQuery } from 'usehooks-ts';

const RevenueSection = () => {
  const isTablet = useMediaQuery('(max-width:991.98px)');

  return (
    <section className={style.revenue}>
      <div className='__container'>
        <Title.H2 className={style.revenue__title}>
          Revenue <span>Share Incentives for</span> Governance
        </Title.H2>
        <div className={style.revenueText}>
          <div className={style.col}>
            <Text className={style.col__text} secondary>
              As referenced previously, holders of TAI tokens will have the
              opportunity to stake their TAI tokens into xTAI in order to
              participate in governing the direction of the protocol, receiving
              incentives, and a share of protocol revenues. xTAI holders receive
              emissions from the protocol, but also receive real yield in the
              form of:
            </Text>
          </div>
          <div className={style.col}>
            <Text className={style.col__text} secondary>
              This encourages longer term staking and offers heavier rewards and
              yield to those that stay engaged with the long-term vision of
              TaiNet, whilst punishing short-term opportunists looking to game
              the system. In addition long term yTAO users are also rewarded
              with a similar setup that boosts their yield vs. competition in
              the form of:
            </Text>
          </div>
        </div>
        <div className={style.revenueBody}>
          <div className={style.col}>
            {isTablet && (
              <div className={style.col__clip}>
                <img src={clip} alt='' />
              </div>
            )}
            <div className={style.revenueCols}>
              <div className={style.revenueCols__item}>
                <Title.H3 className={style.revenueCols__title}>50%</Title.H3>
                <Text secondary className={style.revenueCols__text}>
                  of yTAO mint fees are allocated to xTAI stakers
                </Text>
                <div className={style.circle}></div>
              </div>
              <div className={style.revenueCols__item}>
                <Title.H3 className={style.revenueCols__title}>50%</Title.H3>
                <Text secondary className={style.revenueCols__text}>
                  of yTAO redemption fees are allocated to yTAO stakers
                </Text>
                <div className={`${style.circle} ${style.medium}`}></div>
              </div>
              <div className={style.revenueCols__item}>
                <Title.H3 className={style.revenueCols__title}>50%</Title.H3>
                <Text secondary className={style.revenueCols__text}>
                  of yTAO yield fees are allocated to yTAO stakers
                </Text>
                <div className={`${style.circle} ${style.small}`}></div>
              </div>
            </div>
          </div>
          <div className={style.col}>
            <div className={style.revenueGraph}>
              <div className={style.revenueGraph__item}>
                <Title.H3 className={style.revenueGraph__itemTitle}>
                  50%
                </Title.H3>
                <Text secondary className={style.revenueGraph__itemText}>
                  mint fees are allocated to existing yTAO stakers
                </Text>
              </div>
              <div className={style.revenueGraph__item}>
                <Title.H3 className={style.revenueGraph__itemTitle}>
                  50%
                </Title.H3>
                <Text secondary className={style.revenueGraph__itemText}>
                  redemption fees are allocated to remaining yTAO stakers
                </Text>
              </div>
              <div className={style.revenueGraph__item}>
                <Title.H3 className={style.revenueGraph__itemTitle}>
                  50%
                </Title.H3>
                <Text secondary className={style.revenueGraph__itemText}>
                  yield fees are allocated to yTAO stakers
                </Text>
              </div>
              <div className={style.revenueGraph__bg}>
                <img src={isTablet ? linesMob : lines} alt='' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RevenueSection;
