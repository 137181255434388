import { useEffect, useRef, useState } from 'react';
import Text from '../Text';
import Title from '../Title';
import style from './TextCard.module.scss';

const TextCard = ({ title, text, className, watchVisible }) => {
  const cardRef = useRef(null);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (
      cardRef.current.getBoundingClientRect().top - window.innerHeight <
      -100
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  });

  return (
    <div
      ref={cardRef}
      className={`${style.card} ${
        isDisabled && watchVisible && style.disabled
      } ${className}`}
    >
      <Title.H3 className={style.card__title}>{title}</Title.H3>
      <Text secondary className={style.card__text}>
        {text}
      </Text>
    </div>
  );
};

export default TextCard;
