import Text from '../../components/Text';
import Title from '../../components/Title';
import style from './Main.module.scss';
import arrowIcon from './assets/icons/arrow';
import linesImg from './assets/img/lines.svg';
import ringsImg from './assets/img/rings.png';
import purpleColIcon from './assets/img/purple-col.svg';
import orangeColIcon from './assets/img/orange-col.svg';
import greyColIcon from './assets/img/grey-col.svg';

const MainSection = () => {
  return (
    <main className={style.main}>
      <div className='__container'>
        <div className={style.mainBody}>
          <div className={style.col}>
            <div className={style.colContent}>
              <Title.H1 className={style.colContent__title}>
                The First Yield
                <br />
                -Optimized, Native TAO-Compatible LST for the Bittensor
                ecosystem
              </Title.H1>
              <div className={style.colRow}>
                <Text className={style.colRow__text}>
                  yTAO is TaiNet’s innovative yield-optimized Liquid Staking
                  Token (LST) for the Bittensor ecosystem.
                </Text>
                <a href='' className={style.colRow__link}>
                  {arrowIcon}
                </a>
              </div>
            </div>
            <div className={style.colLines}>
              <img src={linesImg} alt='' />
            </div>
          </div>
          <div className={style.col}>
            <div className={style.colRings}>
              <img src={ringsImg} alt='' />
            </div>

            <div className={style.colBody}>
              <div className={style.colBody__item}>
                <div className={style.colBody__itemImg}>
                  <img src={purpleColIcon} alt='' />
                </div>
                <div className={style.colBody__itemInfo}>
                  <Title.H3>Native TAO Deposits:</Title.H3>
                  <Text secondary>
                    The first TAO LST to accept native TAO deposit in addition
                    to wTAO
                  </Text>
                </div>
              </div>
              <div className={style.colBody__item}>
                <div className={style.colBody__itemImg}>
                  <img src={orangeColIcon} alt='' />
                </div>
                <div className={style.colBody__itemInfo}>
                  <Title.H3>AI-Driven Yield Optimization:</Title.H3>
                  <Text secondary>
                    Maximizing staked TAO yields by by using AI-optimized
                    delegation strategies
                  </Text>
                </div>
              </div>
              <div className={style.colBody__item}>
                <div className={style.colBody__itemImg}>
                  <img src={greyColIcon} alt='' />
                </div>
                <div className={style.colBody__itemInfo}>
                  <Title.H3>Cross-Chain Liquidity:</Title.H3>
                  <Text secondary>
                    Make your staked TAO liquid and deployable across any
                    LayerZero compatible chian
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default MainSection;
