const content = {
  cards: [
    {
      title: 'Native TAO Deposits:',
      text: 'TaiNet pioneers native TAO deposits, eliminating the need for wrapping TAO tokens before staking.',
    },
    {
      title: 'Unlocking $4.5bn Of Trapped Value',
      text: 'As the first TAO LST to accept native TAO deposits, TaiNet unlocks $4.5b of additional addressable market vs wTAO-only LSTs. The larger market also enables higher potential for protocol revenue that can be passed on to yTAO holders as enhanced yield.',
    },
    {
      title: 'Yield-Optimized-By-AI:',
      text: 'TaiNet’s first-to-market AI-driven yield-optimization strategies, this will allow it to outcompete all existing yield-bearing products in the Bittensor ecosystem.',
    },
    {
      title: 'Cross-Chain AI Yield-Optimization:',
      text: 'Having honed its AI algorithms & unique revenue models on Bittensor, TaiNet will then apply these competitive advantages to roll out similar solutions for all Layer 1 AI chains. This will culminate in the ultimate cross-chain yield optimization protocol for the AI blockchain ecosystem.',
    },
  ],
};

export default content;
