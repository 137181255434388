const burgerIcon = (
   <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
   >
      <rect x='17' y='17' width='4' height='4' rx='2' fill='#141414' />
      <rect x='17' y='27' width='4' height='4' rx='2' fill='#141414' />
      <rect x='27' y='17' width='4' height='4' rx='2' fill='#141414' />
      <rect x='27' y='27' width='4' height='4' rx='2' fill='#141414' />
   </svg>
);
export default burgerIcon;
