import Text from '../Text';
import style from './SliderNav.module.scss';
import './sliderStyles.scss';

const SliderNav = ({ prevEl, nextEl, count }) => {
  return (
    <div className={style.nav}>
      <div id={prevEl} className={`${style.nav__btn} slider-nav-btn`}>
        <svg
          width='16'
          height='17'
          viewBox='0 0 16 17'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M10.0601 3.66217L5.71339 8.00883C5.20006 8.52217 5.20006 9.36217 5.71339 9.8755L10.0601 14.2222'
            stroke='white'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </svg>
      </div>
      <Text className={style.nav__counter}>{count}</Text>
      <div id={nextEl} className={`${style.nav__btn} slider-nav-btn`}>
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M5.93994 13.2787L10.2866 8.93208C10.7999 8.41875 10.7999 7.57875 10.2866 7.06542L5.93994 2.71875'
            stroke='white'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </svg>
      </div>
    </div>
  );
};

export default SliderNav;
