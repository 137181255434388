import style from './Unlocking.module.scss';
import Title from './../../components/Title';
import img from './assets/img/img.png';
import TextCard from './../../components/TextCard';
import content from './content';
import { useMediaQuery } from 'usehooks-ts';

const UnlockingSection = () => {
  const isMobile = useMediaQuery('(max-width:1200px)');

  return (
    <section className={style.unlocking}>
      <div className='__container'>
        <Title.H2 className={style.unlocking__title}>
          Unlocking Value <span>in the TaiNet Ecosystem with</span> TAI Tokens
        </Title.H2>
        <div className={style.unlockingBody}>
          <div className={style.unlockingBody__col}>
            <TextCard
              title={content.cards[0].title}
              text={content.cards[0].text}
            />
            <TextCard
              title={content.cards[1].title}
              text={content.cards[1].text}
            />
          </div>
          <div className={style.unlockingBody__img}>
            <img src={img} alt='' />
          </div>
          <div className={style.unlockingBody__col}>
            <TextCard
              title={content.cards[2].title}
              text={content.cards[2].text}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default UnlockingSection;
