import ProgressBar from '../../components/ProgressBar';
import Text from '../../components/Text';
import TextCard from '../../components/TextCard';
import Title from '../../components/Title';
import content from './content';
import style from './Potential.module.scss';
import img from './assets/img/coin.png';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import SliderNav from '../../components/SliderNav';
import { useState } from 'react';
import { Navigation } from 'swiper/modules';

const PotentialSection = ({
  scopeRef,
  progress,
  scrollWrapperRef,
  scrollBlockRef,
  isTablet,
}) => {
  const [sliderCounter, setSliderCounter] = useState('0/0');

  return (
    <section ref={scopeRef} className={style.potential}>
      <div className='__container'>
        <div className={style.potentialBody}>
          <div className={style.col}>
            {!isTablet && (
              <div className={style.col__img}>
                <img src={img} alt='' />
              </div>
            )}
            <Title.H2 className={style.potentialBody__title}>
              Unlocking <span>Business Potential in Liquid</span> Staking
              <span>Protocols</span>
            </Title.H2>
            <Text className={style.potentialBody__text} secondary>
              As with any liquid staking protocol with a stake in a strong,
              emerging blockchain ecosystem, there is huge potential to develop
              a sustainable, revenue generating and profitable business model.
            </Text>

            {!isTablet && (
              <>
                <ProgressBar
                  progress={progress}
                  count={5}
                  className={style.potentialBody__progress}
                />
                <div className={style.potentialBody__progressNums}>
                  <span>1</span>
                  <span>5</span>
                </div>
              </>
            )}
          </div>
          {isTablet && (
            <>
              <SliderNav
                prevEl={'potential-mobile-slider-prev'}
                nextEl={'potential-mobile-slider-next'}
                count={sliderCounter}
              />
              <Swiper
                modules={[Navigation]}
                className={style.potentialSlider}
                autoHeight
                spaceBetween={30}
                navigation={{
                  prevEl: '#potential-mobile-slider-prev',
                  nextEl: '#potential-mobile-slider-next',
                }}
                onSlideChange={(swiper) => {
                  setSliderCounter(
                    `${swiper.realIndex + 1}/${swiper.slides.length}`
                  );
                }}
                onSwiper={(swiper) => {
                  setSliderCounter(
                    `${swiper.realIndex + 1}/${swiper.slides.length}`
                  );
                }}
              >
                <SwiperSlide className={style.potentialSlider__slide}>
                  <TextCard
                    title={content.cards[0].title}
                    text={content.cards[0].text}
                  />
                  <TextCard
                    title={content.cards[1].title}
                    text={content.cards[1].text}
                  />
                </SwiperSlide>
                <SwiperSlide className={style.potentialSlider__slide}>
                  <TextCard
                    title={content.cards[2].title}
                    text={content.cards[2].text}
                  />
                  <TextCard
                    title={content.cards[3].title}
                    text={content.cards[3].text}
                  />
                </SwiperSlide>
                <SwiperSlide className={style.potentialSlider__slide}>
                  <TextCard
                    title={content.cards[4].title}
                    text={content.cards[4].text}
                  />
                </SwiperSlide>
              </Swiper>
            </>
          )}
          {!isTablet && (
            <div className={style.col}>
              <div className={style.potentialScroll} ref={scrollBlockRef}>
                <div
                  ref={scrollWrapperRef}
                  className={style.potentialScroll__wrapper}
                >
                  {content.cards.map((item, index) => (
                    <TextCard
                      key={index}
                      title={item.title}
                      text={item.text}
                      watchVisible={index > 3}
                      className={style.potentialScroll__item}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default PotentialSection;
