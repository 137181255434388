import Text from '../../components/Text';
import Title from '../../components/Title';
import style from './Growth.module.scss';
import clip from './assets/img/clip.svg';
import clipMob from './assets/img/clip-mobile.svg';
import clipMobTop from './assets/img/clip-mobile-top.svg';
import { useMediaQuery } from 'usehooks-ts';

const GrowthSection = () => {
  const isMobile = useMediaQuery('(max-width:991.98px)');

  return (
    <section className={style.growth}>
      <div className={style.growth__clip}>
        <img src={isMobile ? clipMob : clip} alt='' />
      </div>
      {isMobile && (
        <div className={style.growth__clipTop}>
          <img src={clipMobTop} alt='' />
        </div>
      )}
      <div className='__container'>
        <div className={style.growthBody}>
          <div className={style.col}>
            {!isMobile && (
              <Title.H3>
                Incentivizing Participation and Growth in Decentralization
              </Title.H3>
            )}
            <div className={style.growthBody__label}>
              <span>Encouraging, engagement</span>
            </div>
            {isMobile && (
              <Title.H3>
                Incentivizing Participation and Growth in Decentralization
              </Title.H3>
            )}
            <Text secondary className={style.growthBody__text}>
              In the spirit of equitable decentralization, 100% of protocol
              generated revenue is redirected back to participants in the
              ecosystem and encourages and rewards long-term participation and
              growth. The team operates and maintains the 3/3 token tax in order
              to generate ongoing runway for the project, which puts an emphasis
              on incentivizing the core team to ensure ongoing high levels of
              interest, liquidity and trading volume on the TAI token, which
              also benefits all holders in the long term.
            </Text>
            <div className={style.card}>
              <div className={style.card__col}>
                <Title.H3 className={style.card__title}>yTAO</Title.H3>
                <Title.H3 className={style.card__value}>50%</Title.H3>
              </div>
              <div className={style.card__col}>
                <div className={style.cardLabels}>
                  <div className={style.cardLabels__item}>Mint fee revenue</div>
                  <div className={style.cardLabels__item}>
                    Redemption fee revenue
                  </div>
                  <div className={style.cardLabels__item}>
                    Yield fee revenue
                  </div>
                </div>
              </div>
            </div>
            <div className={style.card}>
              <div className={style.card__col}>
                <Title.H3 className={style.card__title}>yTAO</Title.H3>
                <Title.H3 className={style.card__value}>50%</Title.H3>
              </div>
              <div className={style.card__col}>
                <div className={style.cardLabels}>
                  <div className={style.cardLabels__item}>Mint fee revenue</div>
                  <div className={style.cardLabels__item}>
                    Redemption fee revenue
                  </div>
                  <div className={style.cardLabels__item}>
                    Yield fee revenue
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={style.col}>
            <div className={style.colTop}>
              <Title.H3>Total</Title.H3>
              <Text secondary>
                The team operates and maintains the 3/3 token
              </Text>
            </div>
            <div className={style.growthGraph}>
              <Text secondary className={style.growthGraph__text}>
                High TAI <br /> token interest
              </Text>
              <div className={style.growthGraph__item}>
                <div className={style.growthGraph__col}>
                  <Title.H3 className={style.growthGraph__colTitle}>
                    100%
                  </Title.H3>
                  <span
                    className={style.growthGraph__colBar}
                    style={isMobile ? { width: '50%' } : { height: '50%' }}
                  ></span>
                  <Text sm className={style.growthGraph__colText}>
                    Mint fee revenue
                  </Text>
                </div>
                <div className={style.growthGraph__col}>
                  <Title.H3 className={style.growthGraph__colTitle}>
                    100%
                  </Title.H3>
                  <span
                    className={style.growthGraph__colBar}
                    style={isMobile ? { width: '65%' } : { height: '65%' }}
                  ></span>
                  <Text sm className={style.growthGraph__colText}>
                    Redemption fee revenue
                  </Text>
                </div>
                <div className={style.growthGraph__col}>
                  <Title.H3 className={style.growthGraph__colTitle}>
                    100%
                  </Title.H3>
                  <span
                    className={style.growthGraph__colBar}
                    style={isMobile ? { width: '75%' } : { height: '70%' }}
                  ></span>
                  <Text sm className={style.growthGraph__colText}>
                    Yield fee revenue
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GrowthSection;
