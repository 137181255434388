import style from './About.module.scss';
import { useEffect, useRef, useState } from 'react';
import ProgressBar from '../../components/ProgressBar';
import Title from './../../components/Title';
import Text from '../../components/Text';
import { useLottieLoad } from '../../hooks/useLootie';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';

const AboutSection = ({ progress, animWrapperRef, isTablet, sectionRef }) => {
  const animRef = useRef(null);
  const anim2Ref = useRef(null);
  const anim3Ref = useRef(null);

  const [sliderProgress, setSliderProgress] = useState(0);

  const [activeIndex, setActiveIndex] = useState(1);

  const anim = useLottieLoad({
    ref: animRef,
    path: './assets/lootie/about-1.json',
    loop: true,
  });
  const anim2 = useLottieLoad({
    ref: anim2Ref,
    path: './assets/lootie/about-2.json',
    loop: true,
  });
  const anim3 = useLottieLoad({
    ref: anim3Ref,
    path: './assets/lootie/about-3.json',
    loop: true,
  });

  useEffect(() => {
    if (progress >= 0 && progress < 0.33) {
      setActiveIndex(1);
    }
    if (progress >= 0.33 && progress <= 0.66) {
      setActiveIndex(2);
    }
    if (progress > 0.66) {
      setActiveIndex(3);
    }
  }, [progress]);

  const textContent = [
    <>
      <Title.H2>Yield Amplification Strategies</Title.H2>
      <div className={style.colContent}>
        <div className={style.colContent__item}>
          <div className={style.colContent__itemLabel}>Boosted Returns</div>
          <Title.H3 className={style.colContent__itemTitle}>
            Unlocking Enhanced Yields with TaiNet
          </Title.H3>
          <Text secondary className={style.colContent__itemText}>
            TaiNet boosts yields beyond individual staking.This is achieved via
            two mechanisms:
          </Text>
          <div className={style.colContent__itemRow}>
            <Text className={style.item} secondary>
              <span>Protocol Revenue Share:</span> 50% of fees redistributed to
              yTAO holders boosts long-term yields.
            </Text>
          </div>
        </div>
      </div>
    </>,
    <>
      <Title.H2>
        Maximizing Token <span>Flexibility and Yield with Omnichain</span>{' '}
        Utility
      </Title.H2>
      <div className={style.colContent}>
        <div className={style.colContent__item}>
          <div className={style.colContent__itemLabel}>
            Token Interconnection
          </div>
          <Title.H3 className={style.colContent__itemTitle}>
            Interoperability with yTAO's Omnichain Fungible Token
          </Title.H3>
          <Text secondary className={style.colContent__itemText}>
            yTAO, a Layer Zero OFT (Omnichain Fungible Token), enables seamless
            cross-chain transfers without additional wrapped or bridged assets.
            This boosts token utility, allowing its use across 50+ blockchains
            and L2 networks in DeFi applications.
          </Text>
        </div>
        <div className={style.colContent__item}>
          <div className={style.colContent__itemLabel}>Yield Enhancement</div>
          <Title.H3 className={style.colContent__itemTitle}>
            Unlocking DeFi Yield Potential with yTAO
          </Title.H3>
          <Text secondary className={style.colContent__itemText}>
            yTAO's liquidity allows for diverse DeFi investments, boosting
            staking yields. Initial options include liquidity pool
            participation, with broader integration into trading, lending, and
            more as the protocol expands.
          </Text>
        </div>
      </div>
    </>,
    <>
      {' '}
      <Title.H2>
        Taiji Subnet <span>Expansion Empowering yTAO</span> Holders
      </Title.H2>
      <div className={style.colContent}>
        <div className={style.colContent__item}>
          <div className={style.colContent__itemLabel}>Subnet Venture</div>
          <Title.H3 className={style.colContent__itemTitle}>
            TaiNet's Asset Growth Fuels Subnet Operation
          </Title.H3>
          <Text secondary className={style.colContent__itemText}>
            As TaiNet gathers assets, it gains the ability to operate its own
            subnet, speculating on Bittensor subnet yields to generate revenue
            for yTAO holders and bolster TaiNet's influence. Earnings will
            support the acquisition and operation of a TaiNet-owned subnet.
          </Text>
        </div>
      </div>
    </>,
  ];

  return (
    <section ref={sectionRef} className={style.about}>
      <div className='__container'>
        {!isTablet && (
          <div className={style.aboutBody}>
            <div className={style.col}>
              <div
                className={`${style.col__wrapper} 
              ${activeIndex === 1 && style.active}
              `}
              >
                {textContent[0]}
              </div>
              <div
                className={`${style.col__wrapper} ${
                  activeIndex === 2 && style.active
                }`}
              >
                {textContent[1]}
              </div>
              <div
                className={`${style.col__wrapper} ${
                  activeIndex === 3 && style.active
                }`}
              >
                {textContent[2]}
              </div>
            </div>
            <div className={style.col}>
              <ProgressBar progress={progress} count={3} />
              <div className={style.col__label}>
                {activeIndex === 1 && <span>Omnichain Utility</span>}
                {activeIndex === 2 && <span>Taiji Subnet</span>}
                {activeIndex === 3 && <span>Taiji Subnet</span>}
              </div>
              <div className={style.colAnim}>
                <div ref={animWrapperRef} className={style.colAnim__wrapper}>
                  <div className={style.colAnim__wrapperItem} ref={anim}></div>
                  <div className={style.colAnim__wrapperItem} ref={anim2}></div>
                  <div className={style.colAnim__wrapperItem} ref={anim3}></div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isTablet && (
          <div className={style.aboutSlider}>
            <ProgressBar
              progress={sliderProgress}
              count={3}
              isSlider
              className={style.aboutSlider__progress}
            />
            <Swiper
              onSwiper={(e) => {
                setSliderProgress((e.realIndex + 1) / e.slides.length);
              }}
              onSlideChange={(e) => {
                setSliderProgress((e.realIndex + 1) / e.slides.length);
              }}
              // onProgress={(e) => {
              //   // setSliderProgress(e.progress);
              //   // console.log(e.progress - 0.33 / (e.realIndex + 1));
              // }}
              className={style.aboutSlider__content}
              spaceBetween={0}
            >
              <SwiperSlide className={style.slide}>
                <div className={style.aboutSlider__item}>
                  <div className={style.aboutSlider__itemLabel}>
                    Omnichain Utility
                  </div>
                  {textContent[0]}
                </div>
              </SwiperSlide>
              <SwiperSlide className={style.slide}>
                <div className={style.aboutSlider__item}>
                  <div className={style.aboutSlider__itemLabel}>
                    Taiji Subnet
                  </div>
                  {textContent[1]}
                </div>
              </SwiperSlide>
              <SwiperSlide className={style.slide}>
                <div className={style.aboutSlider__item}>
                  <div className={style.aboutSlider__itemLabel}>
                    Taiji Subnet
                  </div>
                  {textContent[2]}
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        )}
      </div>
    </section>
  );
};

export default AboutSection;
